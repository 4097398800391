import React from 'react'
import cl from 'classnames'

import Header from 'widgets/header'
import { Footer } from 'widgets/Footer'
import useSetIsMobileModal from 'features/modal/use-cases/useSetIsMobileModal'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import Home from 'pages/home'

export const MainLayout = () => {
  const { isMobileModal, isSomeModalsOpened } = useIsMobileModal()

  useSetIsMobileModal()

  return (
    <div className="app">
      <Header />
      <main
        className={cl(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Home />
      </main>
      <Footer />
    </div>
  )
}
